import styled from 'styled-components'
import wavesBg from '../../../images/howWeWork/falaHowWeWork.svg'
import wavesBgMobile from '../../../images/howWeWork/wavesmobile.svg'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
  xsmallBreakpoint,
} from '../../../config/css-constants'
import NumberOne from '../../../assets/numberone.svg'
import { draw, opacityToZero } from '../../../assets/keyframes/keyframes'

export const TalkBackground = styled.div`
  position: relative;
  background-image: url(${wavesBgMobile});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 50px 30px 220px 80px;
  @media all and (min-width: ${xsmallBreakpoint}) {
    background-image: url(${wavesBg});
    padding: 50px 30px 180px 80px;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 100px 1.5rem 250px 1.5rem;
    display: flex;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    min-height: 600px;
    padding: 100px 7rem 300px 7rem;
    display: flex;
  }
  @media all and (min-width: ${fullhd}) {
    min-height: 800px;
    padding: 150px 12rem 350px 13rem;
    display: flex;
  }
`

export const TalkBlock = styled.div`
  @media all and (min-width: ${mediumBreakpoint}) {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
`

export const NumberWrapper = styled.div`
  position: absolute;
  left: 20px;
  @media all and (min-width: ${mediumBreakpoint}) {
    position: static;
    width: unset;
    left: unset;
    text-align: center;
  }
`

export const InfoBlockOddDescription = styled.div`
  @media all and (min-width: ${xsmallBreakpoint}) {
    padding-left: 40px;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-left: 50px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 80%;
  }
`

export const YellowDot = styled.div`
  width: 30px;
  height: 30px;
  background-color: #f9c249;
  border-radius: 50%;
  position: absolute;
  right: 30px;
  bottom: 20%;
  @media all and (min-width: ${xsmallBreakpoint}) {
    bottom: 5%;
    right: 5%;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    display: none;
  }
`

export const NumberOneSvgWrapper = styled.div`
  position: relative;
  &:after {
    content: '';
    display: none;
    width: 40px;
    height: 40px;
    background-color: #f9c249;
    border-radius: 50%;
    position: absolute;
    right: 17%;
    bottom: 50px;
    @media all and (min-width: ${mediumBreakpoint}) {
      display: block;
    }
    @media all and (min-width: 1910px) and (max-width: 1930px) {
      display: none;
    }
  }
`

export const NumberOneSvg = styled(NumberOne)`
  width: 40px;
  @media all and (min-width: ${mediumBreakpoint}) {
    width: 100%;
    height: 100%;
    max-height: 250px;
  }
  path {
    stroke: #ffc34a;
    fill: #fff;
    stroke-width: 3;
    stroke-dasharray: 757.6000366210938;
    stroke-dashoffset: 757.6000366210938;
    animation-name: ${(props) => (props.isvisible ? draw : '')};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
`
export const YellowCircle = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 30%;
  top: 350px;
  background-color: #f9c249;
  border-radius: 50%;
  animation: ${opacityToZero} 5.12s forwards;
  display: none;
  @media all and (min-width: 1910px) and (max-width: 1930px) {
    display: block;
  }
`
