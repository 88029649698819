import styled from 'styled-components'
import {
  mediumBreakpoint,
  xsmallBreakpoint,
} from '../../../config/css-constants'
import NumberFour from '../../../assets/numberfour.svg'
import {
  draw,
  fallingDown,
  showingUpPaperIco,
  showingUpHandIco,
} from '../../../assets/keyframes/keyframes'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0 70px;
  @media all and (min-width: ${xsmallBreakpoint}) {
    padding: 30px 70px 50px;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    max-width: 1344px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 180px 70px 150px 70px;
    margin: 0 auto;
  }
`

export const TestBlock = styled.div`
  position: relative;
  padding-bottom: 100px;
  @media all and (min-width: ${mediumBreakpoint}) {
  }
`

export const InfoBlockDescription = styled.div`
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-right: 50px;
  }
`

export const NumberWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 0px;

  @media all and (min-width: ${mediumBreakpoint}) {
    display: block;
    position: static;
    width: unset;
    left: unset;
    text-align: center;
  }
`

export const IconsWrapper = styled.div``

export const TestPaperIco = styled.img`
  position: absolute;
  z-index: 2;
  right: 0;
  top: -100px;
  transform: translateY(-50%);
  width: 50px;
  opacity: 0;
  animation-name: ${(props) => (props.isvisible ? fallingDown : '')};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-delay: 1.5s;
  @media all and (min-width: ${mediumBreakpoint}) {
    animation-name: ${(props) => (props.isvisible ? showingUpPaperIco : '')};
    width: 70px;
    left: unset;
    right: 18%;
    top: 49%;
  }
`

export const TestHandIco = styled.img`
  position: absolute;
  width: 60px;
  right: 150px;
  opacity: 0;
  bottom: 0;
  animation-name: ${(props) => (props.isvisible ? fallingDown : '')};
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-delay: 2.5s;
  @media all and (min-width: ${mediumBreakpoint}) {
    animation-name: ${(props) => (props.isvisible ? showingUpHandIco : '')};
    width: 80px;
    top: 20px;
    right: 10%;
  }
`

export const NumberFourSvg = styled(NumberFour)`
  width: 80px;
  @media all and (min-width: ${mediumBreakpoint}) {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }
  path {
    stroke: #ffc34a;
    fill: #fff;
    stroke-width: 10;
    stroke-dasharray: 1543.4271240234375;
    stroke-dashoffset: 1543.4271240234375;
    animation-name: ${(props) => (props.isvisible ? draw : '')};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
`
