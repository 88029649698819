import React from 'react'

import TalkSection from '../components/CustomHowWeWork/WorkTalkSection/WorkTalkSection'
import DesignSection from '../components/CustomHowWeWork/WorkDesignSection/WorkDesignSection'
import CodeSection from '../components/CustomHowWeWork/WorkCodeSection/WorkCodeSection'
import TestSection from '../components/CustomHowWeWork/WorkTestSection/WorkTestSection'

const HowWeWork = () => (
  <>
    <section className="talk">
      <TalkSection />
    </section>
    <section className="design">
      <DesignSection />
    </section>
    <section className="code">
      <CodeSection />
    </section>
    <section className="test">
      <TestSection />
    </section>
  </>
)

export default HowWeWork
