import styled from 'styled-components'
import { mediumBreakpoint } from '../../../config/css-constants'
import { draw } from '../../../assets/keyframes/keyframes'

import NumberThree from '../../../assets/numberthree.svg'

export const CodeBlock = styled.div`
  position: relative;
  padding: 0 30px 100px 80px;
  @media all and (min-width: ${mediumBreakpoint}) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 50px 70px;
    max-width: 1344px;
    margin: 0 auto;
  }
`

export const InfoBlockOddDescription = styled.div`
  padding-left: 40px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-left: 50px;
  }
`

export const CodeDescription = styled.p`
  font-size: 1rem;
  position: relative;
  &:after {
    content: '';
    margin: 15px 0 0 0px;
    height: 20px;
    display: block;
    background-color: #f9c249;
    width: ${(props) => (props.isvisible ? '100px' : '0')};
    transition: all 1s ease;
    @media all and (min-width: ${mediumBreakpoint}) {
      margin-left: 50px;
      margin-top: 5px;
    }
  }
`

export const NumberWrapper = styled.div`
  position: absolute;
  left: 20px;
  @media all and (min-width: ${mediumBreakpoint}) {
    position: static;
    width: unset;
    left: unset;
    text-align: center;
  }
`

export const NumberThreeSvg = styled(NumberThree)`
  width: 80px;
  @media all and (min-width: ${mediumBreakpoint}) {
    width: 100%;
  }
  path {
    stroke: #ffc34a;
    fill: #fff;
    stroke-width: 1;
    stroke-dasharray: 403.5420837402344;
    stroke-dashoffset: 403.5420837402344;
    animation-name: ${(props) => (props.isvisible ? draw : '')};
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
`
