import React, { Component } from 'react'
import {
  TalkBackground,
  TalkBlock,
  NumberWrapper,
  NumberOneSvgWrapper,
  InfoBlockOddDescription,
  YellowDot,
  NumberOneSvg,
  YellowCircle,
} from './styles'
import { Title, Description } from '../WorkReusableCode'
import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'

import { TimelineMax } from 'gsap'

class WorkTalkSection extends Component {
  constructor(props) {
    super(props)
    this.circle = React.createRef()
  }

  componentDidMount() {
    const tl = new TimelineMax({ repeat: 0, delay: 2.2 })
    tl.to(this.circle, 4, {
      bezier: {
        type: 'soft',
        values: [
          {
            x: 300,
            y: 300,
            scale: 1.2,
          },
          {
            x: 800,
            y: 400,
            scale: 1.4,
          },
          {
            x: 850,
            y: -100,
            scale: 1.3,
          },
          {
            x: 760,
            y: 400,
            scale: 1.2,
          },
        ],
        timeResolution: 10,
      },
    })
  }

  render() {
    return (
      <>
        <TalkBackground>
          <TalkBlock>
            <NumberWrapper>
              <VisibilitySensor once>
                {({ isVisible }) => (
                  <NumberOneSvgWrapper style={{ height: '300px' }}>
                    <NumberOneSvg isvisible={isVisible ? 1 : 0} />
                  </NumberOneSvgWrapper>
                )}
              </VisibilitySensor>
              <YellowCircle ref={(div) => (this.circle = div)} />
            </NumberWrapper>
            <InfoBlockOddDescription>
              <Title>Talk &amp; analyze</Title>
              <Description>
                The core focus of our work is our client. We firstly talk to
                understand the needs, challenges and opportunities of their
                businesses. This process helps us to draw a specific analysis
                and evaluate your firm.
              </Description>
            </InfoBlockOddDescription>
          </TalkBlock>
          <YellowDot />
        </TalkBackground>
      </>
    )
  }
}

export default WorkTalkSection
