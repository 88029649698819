import React from 'react'
import {
  Wrapper,
  InfoBlockDescription,
  NumberWrapper,
  TestPaperIco,
  TestHandIco,
  TestBlock,
  NumberFourSvg,
  IconsWrapper,
} from './styles'
import { Title, Description } from '../WorkReusableCode'
import paperIco from '../../../images/howWeWork/07cards.svg'
import handIco from '../../../images/howWeWork/06hand.svg'

import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'

const WorkTestSection = () => (
  <Wrapper>
    <TestBlock>
      <InfoBlockDescription>
        <Title>Test &amp; develop</Title>
        <Description>
          Our professional approach is characterised by continuous testing of
          your software to make sure that it runs smoothly and without any
          disruption. Thanks to our use of the latest technology, our clients
          feel confident that their businesses are safe and protected.
        </Description>
      </InfoBlockDescription>
    </TestBlock>
    <NumberWrapper>
      <VisibilitySensor once>
        {({ isVisible }) => (
          <IconsWrapper>
            <NumberFourSvg isvisible={isVisible ? 1 : 0} />
            <TestPaperIco
              isvisible={isVisible ? 1 : 0}
              src={paperIco}
              alt={''}
            />
            <TestHandIco isvisible={isVisible ? 1 : 0} src={handIco} alt={''} />
          </IconsWrapper>
        )}
      </VisibilitySensor>
    </NumberWrapper>
  </Wrapper>
)

export default WorkTestSection
