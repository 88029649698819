import styled from 'styled-components'

const tabletSize = `(min-width: 768px)`

export const Title = styled.h2`
  font-size: 1.4rem;
  text-transform: uppercase;
  padding-bottom: 20px;
  font-weight: bold;
  @media all and ${tabletSize} {
    padding-bottom: 40px;
  }
`

export const Description = styled.p`
  font-size: 1rem;
`
