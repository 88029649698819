import styled from 'styled-components'
import { mediumBreakpoint } from '../../../config/css-constants'
import designDots from '../../../images/howWeWork/offerhowwework-34.svg'
import NumberTwo from '../../../assets/numbertwo.svg'
import { draw, opacity } from '../../../assets/keyframes/keyframes'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0 70px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 50px 70px;
    max-width: 1344px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
  }
`

export const DesignBlock = styled.div`
  position: relative;
  padding: 150px 0 100px;
`

export const InfoBlockDescription = styled.div`
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-right: 100px;
  }
`

export const DesignDescription = styled.p`
  font-size: 1rem;
  position: relative;
  &:before {
    content: '';
    background-image: url(${designDots});
    width: 130px;
    height: 130px;
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    left: -100px;
    top: 50%;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    &:before {
      display: none;
    }
  }
`

export const NumberWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 0px;
  @media all and (min-width: ${mediumBreakpoint}) {
    position: relative;
    top: 20px;
    width: unset;
    left: unset;
    text-align: center;
  }
`

export const NumberTwoSvgWrapper = styled.div``

export const NumberTwoSvg = styled(NumberTwo)`
  width: 100px;
  text {
    stroke: #ffc34a;
    fill: #fff;
    stroke-width: 10;
    stroke-dasharray: 2000;
    stroke-dashoffset: 2000;
    animation-name: ${(props) => (props.isvisible ? draw : '')};
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-timing-function: linear;
  }
  .animated-path {
    fill-opacity: 0;
    animation-name: ${(props) => (props.isvisible ? opacity : '')};
    animation-delay: 1s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  #first-path {
    animation-name: ${(props) => (props.isvisible ? opacity : '')};
    animation-delay: 3s;
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
  }
  #second-path {
    animation-name: ${(props) => (props.isvisible ? opacity : '')};
    animation-delay: 1s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  #fourth-path {
    animation-name: ${(props) => (props.isvisible ? opacity : '')};
    animation-delay: 1.7s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  @media all and (min-width: ${mediumBreakpoint}) {
    width: 100%;
    height: 100%;
    height: 400px;
    max-height: 400px;
  }
`
