import React from 'react'
import {
  CodeBlock,
  NumberWrapper,
  InfoBlockOddDescription,
  CodeDescription,
  NumberThreeSvg,
} from './styles'
import { Title } from '../WorkReusableCode'
import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'

const WorkCodeSection = () => {
  return (
    <CodeBlock>
      <NumberWrapper>
        <VisibilitySensor once>
          {({ isVisible }) => (
            <div style={{ minHeight: '200px' }}>
              <NumberThreeSvg isvisible={isVisible ? 1 : 0} />
            </div>
          )}
        </VisibilitySensor>
      </NumberWrapper>
      <InfoBlockOddDescription>
        <Title>Code</Title>
        <VisibilitySensor once>
          {({ isVisible }) => (
            <div style={{ minHeight: '200px' }}>
              <CodeDescription isvisible={isVisible ? 1 : 0}>
                Once a client accepts the product’s design, the next stage
                involves backing the product with a specifically tailored code.
                This is done by our software engineers, who are highly-regarded
                specialists in Python and ReactJS.
              </CodeDescription>
            </div>
          )}
        </VisibilitySensor>
      </InfoBlockOddDescription>
    </CodeBlock>
  )
}

export default WorkCodeSection
