import React from 'react'
import {
  Wrapper,
  DesignBlock,
  InfoBlockDescription,
  NumberWrapper,
  DesignDescription,
  NumberTwoSvg,
  NumberTwoSvgWrapper,
} from './styles'
import { Title } from '../WorkReusableCode'
import VisibilitySensor from '../../VisibilitySensor/VisibilitySensor'

const WorkDesignSection = () => {
  return (
    <Wrapper>
      <DesignBlock>
        <InfoBlockDescription>
          <Title>Design</Title>
          <DesignDescription>
            Our team, then, starts working on the design of the product. The
            team of software engineers and UX/UI designers work collaboratively
            on every stage of the production to make sure that the product is
            working properly and is structured concisely.
          </DesignDescription>
        </InfoBlockDescription>
      </DesignBlock>
      <NumberWrapper>
        <VisibilitySensor once>
          {({ isVisible }) => (
            <NumberTwoSvgWrapper style={{ height: '300px' }}>
              <NumberTwoSvg isvisible={isVisible ? 1 : 0} />
            </NumberTwoSvgWrapper>
          )}
        </VisibilitySensor>
      </NumberWrapper>
    </Wrapper>
  )
}

export default WorkDesignSection
